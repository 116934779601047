<template>
  <div>
    <b-row>
      <b-col
        xs="12"
        sm="6"
        md="8"
        lg="8"
        xl="9"
        class="d-flex justify-content-between pb-1 align-items-center"
      >
        <div class="d-flex w-100 justify-content-between">
          <h1>
            {{ $t('expenses') }}
          </h1>
        </div>
      </b-col>
      <b-col
        xs="12"
        sm="6"
        md="4"
        lg="4"
        xl="3"
      >
        <income-total-card
          :label="'totalExcomes'"
          :total-income="total"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        sm="12"
        class="mb-2"
      >
        <div class="d-flex align-items-center">
          <span class="mr-2">{{ $t('show') }} </span>
          <b-form-select
            :value="expensesPagination.per_page"
            style="width:100px"
            :options="perPageOptions"
            @change="changePerPageValue($event)"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="6"
        sm="6"
        lg="3"
      >
        <b-form-group :label="$t('fromDate')">
          <b-form-datepicker
            v-model="query.dates.begin"
            reset-button
            @input="filterExpenses"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        sm="6"
        lg="3"
      >
        <b-form-group :label="$t('toDate')">
          <b-form-datepicker
            v-model="query.dates.end"
            reset-button
            @input="filterExpenses"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        sm="6"
        lg="3"
      >
        <b-form-group :label="$t('status')">
          <b-form-select
            v-model="query.status"
            :options="expenseStatusOptions"
            @change="filterExpenses"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        sm="6"
        lg="3"
      >
        <b-form-group :label="$t('supplier')">
          <b-form-select
            v-model="query.supplier"
            :options="supplierOptions"
            @change="filterExpenses"
          />
        </b-form-group>
      </b-col>
      <!-- Filtro por categoría -->
      <b-col
        cols="6"
        sm="6"
        lg="3"
      >
        <b-form-group :label="$t('category')">
          <b-form-select
            v-model="query.expensetypecatalog"
            :options="expensetypecatalogOptions"
            @change="onCategoryChange(query.expensetypecatalog)"
          />
        </b-form-group>
      </b-col>

      <!-- Filtro por subcategorías dinámicas -->
      <b-col
        v-for="(subcategoryOptions, index) in subcategories"
        :key="index"
        cols="9"
        sm="6"
        lg="3"
      >
        <b-form-group :label="$t('subcategory') + ' ' + (index + 1)">
          <b-form-select
            v-model="selectedSubcategories[index]"
            :options="subcategoryOptions.map(subcat => ({ text: subcat.name, value: subcat.id }))"
            @change="onSubcategoryChange(selectedSubcategories[index], index)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay :show="loading">
          <expenses-table
            :is-material="false"
            :expenses="expenses"
          />
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-pagination
          :value="expensesPagination.page"
          :total-rows="expensesPagination.total_objects"
          :per-page="expensesPagination.per_page"
          aria-controls="my-table"
          @change="nextPage($event)"
        />
      </b-col>
    </b-row>
    <div class="float-button">
      <b-button
        :to="{
          name: 'newExpense',
          params: { projectId: $route.params.projectId },
          query: { createType: 'direct_cost' },
        }"
        variant="primary"
        class="add-button"
      >
        <PlusIcon size="2x" />
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { PlusIcon } from 'vue-feather-icons'
import ExpensesTable from '@/components/expenses/ExpensesTable.vue'
import IncomeTotalCard from '@/components/income/IncomeTotalCard.vue'

export default {
  components: {
    ExpensesTable,
    IncomeTotalCard,
    PlusIcon,
  },
  data() {
    return {
      loading: false,
      perPageOptions: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
      ],
      expenseStatusOptions: [
        { text: this.$t('all'), value: null },
        { text: this.$t('paid'), value: 'paid' },
        { text: this.$t('credit'), value: 'credit' },
        { text: this.$t('expired'), value: 'expired' },
        { text: this.$t('received'), value: 'received' },
      ],
      expensetypecatalogOptions: [
        { text: this.$t('all'), value: null },
      ],
      supplierOptions: [
        { text: this.$t('all'), value: null },
      ],
      subcategories: [], // Array para almacenar niveles de subcategorías
      selectedSubcategories: [], // Almacenar los valores seleccionados para cada nivel
      query: {
        dates: {},
        status: null,
        supplier: null,
        expensetypecatalog: null, // Será "Todos" si es null
        subcategory: null, // Subcategoría seleccionada
      },
    }
  },
  computed: {
    ...mapGetters('projects', ['project']),
    ...mapGetters('expenses', [
      'expenses',
      'expensesPagination',
      'expensesTotal',
    ]),
    total() {
      return `$${this.$options.filters.money(this.expensesTotal)}`
    },
    isMaterial() {
      return this.expenses[0]?.expense_type_catalog.name === 'Material'
    },
  },

  mounted() {
    this.loading = true
    this.fetchExpenses({
      projectId: this.$route.params.projectId,
      pagination: { page: 1, per_page: 10 },
    }).then(() => {
      // // Extraer todas las categorías una sola vez cuando los gastos hayan sido cargados
      // const categories = this.getUniqueExpenseTypeCatalogs(this.expenses)
      // this.expensetypecatalogOptions = [
      //   { text: this.$t('all'), value: null },
      //   ...categories.map(name => ({ text: name, value: name })),
      // ]
      // Extraer todos los proveedores cuando los gastos hayan sido cargados
      const suppliers = this.getUniqueSuppliers(this.expenses)
      this.supplierOptions = [
        { text: this.$t('all'), value: null },
        ...suppliers.map(name => ({ text: name, value: name })),
      ]
    }).finally(() => {
      this.loading = false
    })
    const { projectId } = this.$route.params
    // Llamada al endpoint para obtener las categorías
    this.fetchExpensesTypeCatalogs(projectId)
      .then(categories => {
        // Verifica que la respuesta contiene la propiedad 'data'
        if (categories && categories.data) {
          // Mapear las categorías recibidas a expensetypecatalogOptions
          this.expensetypecatalogOptions = [
            { text: this.$t('all'), value: null }, // Opción "Todos"
            ...categories.data.map(category => ({
              text: category.name,
              value: category.id, // Asegúrate de que "id" es el campo correcto para el value
              subcategories: category.subcategories, // Asegúrate de que las subcategorías están presentes
            })),
          ]
          console.log('Opciones de categorías:', this.expensetypecatalogOptions)
        }
      })
      .catch(error => {
        console.error('Error al obtener las categorías:', error)
      })
      .finally(() => {
        this.loading = false
      })
  },
  // watch: {
  //   // // Actualiza las categorías disponibles cuando cambian los datos de los gastos
  //   // expenses(newExpenses) {
  //   //   const allCategories = this.getUniqueExpenseTypeCatalogs(newExpenses)
  //   //   this.expensetypecatalogOptions = [
  //   //     { text: this.$t('all'), value: null },
  //   //     ...allCategories.map(name => ({ text: name, value: name })),
  //   //   ]
  //   // },
  // },
  methods: {
    ...mapMutations('expenses', {
      changePagination: 'CHANGE_PAGINATION',
    }),
    ...mapActions('expenses', ['fetchExpenses']),
    ...mapActions('groupedExpenses', ['fetchExpensesTypeCatalogs']),
    changePerPageValue(value) {
      this.changePagination({ field: 'per_page', value })
      this.loading = true
      this.fetchExpenses({
        projectId: this.$route.params.projectId,
        pagination: this.expensesPagination,
      }).finally(() => {
        this.loading = false
      })
    },
    // Método para extraer los nombres únicos de expense_type_catalog.name
    getUniqueExpenseTypeCatalogs(expenses) {
      const uniqueCatalogs = expenses
        .map(expenseTypeCatalog => expenseTypeCatalog.name)
        .filter((name, index, self) => name && self.indexOf(name) === index)
      return uniqueCatalogs
    },
    // Método para extraer los proveedores únicos de los gastos
    getUniqueSuppliers(expenses) {
      const uniqueSuppliers = expenses
        .map(expense => expense.supplier)
        .filter((supplier, index, self) => supplier && self.indexOf(supplier) === index)
      return uniqueSuppliers
    },
    ...mapActions('groupedExpenses', ['fetchSubExpenseTypeCatalog']),
    onCategoryChange(selectedCategoryId) {
      this.query.expensetypecatalog = selectedCategoryId
      this.subcategories = []
      this.selectedSubcategories = []

      // Llamada al endpoint para obtener subcategorías
      if (selectedCategoryId) {
        const { projectId } = this.$route.params
        this.fetchSubExpenseTypeCatalog({ projectId, expenseTypeId: selectedCategoryId })
          .then(category => {
            if (category.subcategories && category.subcategories.length) {
              this.subcategories = [...this.subcategories, category.subcategories]
            }
            this.filterExpenses() // Filtrar gastos después de obtener subcategorías
          })
          .catch(error => {
            console.error('Error al obtener subcategorías:', error)
          })
      } else {
        this.filterExpenses() // Si no se selecciona ninguna categoría, se filtran los gastos
      }
    },

    // Método para manejar la selección de subcategorías
    onSubcategoryChange(selectedSubcategoryId, level) {
      this.selectedSubcategories[level] = selectedSubcategoryId
      this.subcategories.splice(level + 1, this.subcategories.length) // Limpiar subcategorías de niveles inferiores

      if (selectedSubcategoryId) {
        const { projectId } = this.$route.params
        this.fetchSubExpenseTypeCatalog({ projectId, expenseTypeId: selectedSubcategoryId })
          .then(category => {
            if (category.subcategories && category.subcategories.length) {
              this.subcategories.push(category.subcategories) // Añadir nuevas subcategorías
            }
            this.filterExpenses() // Filtrar gastos después de la selección de la subcategoría
          })
          .catch(error => {
            console.error('Error al obtener subcategorías:', error)
          })
      } else {
        this.filterExpenses() // Si no se seleccionó ninguna subcategoría, se filtran los gastos
      }
    },

    filterExpenses() {
      let dates = {}
      if (this.query.dates.begin?.length) {
        dates.start_date = this.query.dates.begin
      }
      if (this.query.dates.end?.length) {
        dates.end_date = this.query.dates.end
      }
      if (!Object.keys(dates).length) {
        dates = null
      }

      // Función recursiva para obtener todos los IDs de subcategorías anidadas
      const getAllSubcategoryIds = category => {
        let ids = []
        if (category && category.id) {
          ids.push(category.id) // Añadir el ID de la categoría actual solo si existe
        }
        if (category.subcategories && category.subcategories.length > 0) {
          category.subcategories.forEach(subcat => {
            ids = ids.concat(getAllSubcategoryIds(subcat)) // Llamada recursiva para añadir IDs de subcategorías
          })
        }
        return ids
      }

      // Iniciar el array con la categoría principal seleccionada
      let categoryIds = []

      // Si se selecciona "Todos" (que tiene el valor null), enviar null a la API
      if (this.query.expensetypecatalog === null) {
        categoryIds = null // Enviar null para que la API devuelva todos los egresos
      } else {
        // Si no se ha seleccionado una subcategoría, incluir la categoría principal
        if (!this.selectedSubcategories.length && this.query.expensetypecatalog) {
          categoryIds.push(this.query.expensetypecatalog) // Añadir el ID principal directamente si no hay subcategorías seleccionadas
        }

        // Verificar si la categoría seleccionada tiene subcategorías y agregar sus IDs
        const selectedCategory = this.expensetypecatalogOptions.find(cat => cat.value === this.query.expensetypecatalog)

        if (selectedCategory && selectedCategory.subcategories && selectedCategory.subcategories.length > 0) {
          const subcategoryIds = getAllSubcategoryIds(selectedCategory)
          categoryIds = categoryIds.concat(subcategoryIds) // Agregar las subcategorías de la categoría seleccionada
        }

        // Agregar también las subcategorías seleccionadas y sus anidadas
        this.selectedSubcategories.forEach((subcategoryId, index) => {
          const subcategory = this.subcategories[index]?.find(subcat => subcat.id === subcategoryId)
          if (subcategory) {
            categoryIds = getAllSubcategoryIds(subcategory) // Reemplazar categoryIds con subcategorías anidadas, sin añadir la principal
          }
        })

        // Eliminar duplicados del array de IDs
        categoryIds = [...new Set(categoryIds)] // Usar Set para eliminar duplicados
      }

      console.log('Ids de categorías y subcategorías seleccionadas:', categoryIds)

      // Si se selecciona "Todos" o no hay IDs, permitir la llamada a la API
      if (categoryIds === null || categoryIds.length > 0) {
        this.loading = true
        this.fetchExpenses({
          projectId: this.$route.params.projectId,
          supplier: this.query.supplier,
          status: this.query.status,
          dates,
          expensetypecatalog: categoryIds, // Enviar null o array de IDs de categorías y subcategorías
          pagination: this.expensesPagination,
        }).finally(() => {
          this.loading = false
        })
      } else {
        console.error('No se seleccionaron categorías o subcategorías')
      }
    },
  },
}
</script>

<style>
.float-button {
  position: fixed;
  bottom: 70px;
  right: 30px;
  z-index: 99;
}

.add-button{
  border-radius: 50% !important;
  padding: 0;
  width: 62px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
